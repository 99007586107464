@import url(../../../stylesheets/tools/media-queries.css);

.container {
  display: flex;
  align-items: center;

  @mixin desktop {
    flex-direction: row;
  }
}

.limitedWidth {
  position: relative;
}

.modal {
  position: absolute;
  background: white;
  padding: 0.625rem 0.625rem 0;
  z-index: 1060;
  box-shadow: 0 0 6px 0 var(--gray-500);
  border-radius: 6px;
  top: 2rem;
  left: 1.4rem;
  right: 1.4rem;

  &.topPositioned {
    top: 0.25rem;
    left: 0;
    right: 0;
  }
}
